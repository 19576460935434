import { ORDER_CONTACT_CHANNEL} from '@/constants/orderEnum'
import { ref} from 'vue'
export const useContactChannel = () => {

	const channels = ref([
		{ key: ORDER_CONTACT_CHANNEL.FACEBOOK, name: 'FB', icon: require('@/assets/images/contact-channels/facebook_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.LINE, name: 'LINE', icon:  require('@/assets/images/contact-channels/line_icon.png'),selected: false },
		{ key: ORDER_CONTACT_CHANNEL.INSTAGRAM, name: 'IG', icon: require('@/assets/images/contact-channels/instagram_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.TIKTOK, name: 'TIKTOK', icon: require('@/assets/images/contact-channels/tiktok_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.SHOPEE, name: 'SHOPEE', icon:  require('@/assets/images/contact-channels/shopee_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.LAZADA, name: 'LAZ', icon: require('@/assets/images/contact-channels/lazada_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.X, name: 'X', icon: require('@/assets/images/contact-channels/x_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.WEBSITE, name: 'WWW', icon: require('@/assets/images/contact-channels/web_icon.png'), selected: false },
		{ key: ORDER_CONTACT_CHANNEL.ETC, name: 'ETC.', icon: require('@/assets/images/contact-channels/etc_icon.png'), selected: false },
	])

	return {
		channels,
	}
}